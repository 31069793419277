import React, { useState, useCallback, useRef } from "react";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { ButtonsWrapper } from "./AdminTableRequestsPageStyles";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import { Button, Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import {
  createInfoCell,
  createCellWithChildren,
  createSelectCell
} from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import Loader from "../../atoms/Loader/LoaderAtom";
import AdminTableRejectDialog from "./AdminTableRejectDialog";
import useApiService from "../../utilities/useApiService";
import { PrintButton } from "../../atoms/PrintButton/PrintButtonAtom";
import { mediumButton } from '../../utilities/style';
import { printStyles } from "../../atoms/PrintButton/PrintButtonAtomStyles";
import { usePharmacyEnabledStatus } from "../../context/PharmacyEnabledStatusContext";
import { isRoleIncluded } from "../../utilities/checkUserRole";
import { useAuthState } from "../../context/AuthContext";
import { UserRole } from "../../utilities/Enums";

export interface AdminTableRequestsPageProps {}

type updateStatusArgsType = {
  selectedOption: string;
  itemId: any;
  comment?: string
}


let selectOptions = [
  { value: "RETURNABLE", label: "Returnable" },
  { value: "CHEQUE_RECIEVED", label: "Check Received" }
]

const createRequestsRows = (
  data: Array<any>,
  children: any,
  updateStatus: any,
  userRole: string
) => {
  const rows: Array<TableRowProps> = [];
  data.forEach((entry) => {
    let status = !entry.status ? entry.itemStatus : entry.status;

    const statusCell =
      (status === "RETURNABLE" || status === "CHEQUE_RECIEVED") &&
      isRoleIncluded([UserRole.Admin, UserRole.Warehouse], userRole)
        ? createSelectCell(selectOptions, status, (event: any) => {
            updateStatus({
              selectedOption: event.target.value,
              itemId: entry.id,
            });
          })
        : createInfoCell(status);

    const approveRejectCell = 
      isRoleIncluded([UserRole.Admin, UserRole.Warehouse], userRole)
          ? createCellWithChildren(children(status, entry))
          : null;
      
    const row: TableRowProps = createRow(
      createInfoCell(entry.ndc),
      createInfoCell(entry.description),
      createInfoCell(entry.manufacturer),
      createInfoCell(entry.strength),
      createInfoCell(entry.dosage),
      createInfoCell(entry.packageSize),
      createInfoCell(entry.controlledSubstanceCode),
      createInfoCell(entry.fullQuantity),
      createInfoCell(entry.partialQuantity),
      createInfoCell(parseFloat(entry.extendedPrice).toFixed(2)),
      createInfoCell(parseFloat(entry.extendedUnitPrice).toFixed(2)),
      statusCell,
      createInfoCell(entry.createdAt),
      approveRejectCell
    );
    rows.push(row);
  });
  return rows;
};

const AdminTableRequestsPage: React.FC<AdminTableRequestsPageProps> = (
  props
) => {
  const { getMethod, postMethod, putMethod } = useApiService();
  const { user } = useAuthState();
  const userRole = user!.role;
  const params: any = useParams();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();
  const [open, setOpen] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { pharmacyEnabledStatusMap } = usePharmacyEnabledStatus();
  const queryClient = useQueryClient();
  const labelRef = useRef(null);

  const getItems = async () => {
    startLoading(loaderDispatch);
    let response = await getMethod(
      `/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}/items`
    );
    stopLoading(loaderDispatch);
    return response.data;
  };
  const { data: items } = useQuery("items", getItems);


  const putUpdateStatus = async ({ selectedOption, itemId, comment }: updateStatusArgsType) => {
    const response = await putMethod(`/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}/items/${itemId}/status`, {
      valid: selectedOption
    });
    return { updatedItem: response.data, itemId, comment };
  }
  const { mutate: updateStatus } = useMutation(putUpdateStatus, {
    onSuccess: async ({ updatedItem, itemId, comment }) => {
      if (updatedItem?.status === "N_RETURNABLE" || updatedItem?.itemStatus === "N_RETURNABLE") {
        addComment({
          comment,
          itemId
        })
      } else {
        updateCachedData({ updatedItem, itemId })
      }
    }
  });

  const postComment = async ({ comment, itemId }: any) => {
    const response = await postMethod(`/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}/items/${itemId}/comments`, {
      comment
    })
    return { updatedItem: response.data, itemId };
  };
  const { mutate: addComment } = useMutation(postComment, {
    onSuccess: ({ updatedItem, itemId }) => {
      updateCachedData({ updatedItem, itemId });
    }
  })

  const updateCachedData = async ({ updatedItem, itemId }: any) => {
    const newItems = items.map((obj: any) => {
      let returnObj = { ...obj };
      if (returnObj.id === itemId) {
        returnObj = updatedItem;
      }
      return returnObj;
    });
    console.log("New data", newItems)
    await queryClient.setQueryData("items", newItems);
  }

  const renderChildren = useCallback((status = "PENDING", item) => {
    return status !== "PENDING" ? (
      <ButtonsWrapper className="exclude-print"></ButtonsWrapper>
    ) : (
      <ButtonsWrapper className="exclude-print">
        {isRoleIncluded([UserRole.Admin, UserRole.Warehouse], userRole) && (
        <Button
          onClick={() => {
            setOpen(true);
            setSelectedItem(item);
            updateStatus({
              selectedOption: "RETURNABLE",
              itemId: item.id
            });
          }}
          disabled={!pharmacyEnabledStatusMap[params.pharmacyId]}
          className="approveButton"
        >
          APPROVE
        </Button>
      )}
        {isRoleIncluded([UserRole.Admin, UserRole.Warehouse], userRole) && (
        <BigRedButton
          text="REJECT"
          version={ButtonVersion.SMALL}
          onClick={() => {
            setOpenReject(true);
            setSelectedItem(item);
          }}
          disabled={!pharmacyEnabledStatusMap[params.pharmacyId]}
        />
      )}
      </ButtonsWrapper>
    );
  }, []);

  const rows: Array<TableRowProps> = items
    ? createRequestsRows(
      items,
      renderChildren,
      updateStatus,
      userRole
    )
    : [];

  const header = {
    cells: [
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "NDC",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Description",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Manufacturer",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Strength",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Dosage",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Pkg Size",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "CSC",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Full Qty",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Partial Qty",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Extended Price",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Extended Unit Price",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Status",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "Added at",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => { },
        text: "",
        className:"exclude-print",
      }
    ],
  };

  const totalSum = items ? items.reduce((sum: string, item: any) => sum + (item.extendedPrice || 0) + (item.extendedUnitPrice || 0), 0).toFixed(2) : '0.00';

  return loading ? (
    <Loader />
  ) : (
    <>
      <div style={{ marginBottom: 40 }} ref={labelRef}>
      {items && items.length > 0 && (
        <div className="print-header">
            <span>
            Pharmacy Name: {items[0].pharmacyDoingBusinessAs}
            </span>
            <span>
            Date: {items[0].returnRequestCreatedAt}
          </span>
        </div>
          )}
        <h1 className="print-title">Expected Returns</h1>
        <style>{printStyles}</style>
        <TableOrganism 
          rows={rows} 
          header={header} 
          tableClassName="print-table"
        />
        <h2 className="total">
           Total: {totalSum}
          </h2>
      </div>
      <AdminTableRejectDialog
        open={openReject}
        selectedItem={selectedItem}
        updateStatus={updateStatus}
        addComment={addComment}
        onClose={() => {
          setOpenReject(false);
          setSelectedItem(null);
        }}
      />
      <PrintButton
        labelRef={labelRef}
        buttonText={"Print"}
        buttonWidth={mediumButton.width} 
        buttonHeight={'80px'}
      ></PrintButton>
    </>
  );
};

export default AdminTableRequestsPage;
